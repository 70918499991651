<template>
  <router-link class="blog-card--router" :to="{ name: 'Blog', query: { id } }">
    <div class="blog-card">
      <div class="blog-card__image-container">
        <img class="blog-card__image" :src="image" alt="Blog Image" />
      </div>
      <div class="blog-card__content">
        <p class="blog-card__content__date">{{ date }}</p>
        <h4 class="blog-card__content__title">
          {{ title }}
        </h4>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    image: { type: String },
    title: { type: String },
    date: { type: String },
    id: { type: [Number, String] },
  },
  computed: {
    blogImage() {
      return require(`../assets/home/${this.image}.jpg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-card--router {
  outline: none;
}
.blog-card {
  margin: 3rem auto;
  padding: 2rem;
  background-color: $color-white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-md;
  transition: 0.25s ease-in-out;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: $box-shadow-md-hover;
  }

  &__image {
    width: 100%;
    border-radius: $border-radius-lg;
  }

  &__content {
    &__date {
      margin-top: 2.2rem;
      margin-bottom: 0.9rem;
      font-size: 1.5rem;
      color: $color-purple-40;
    }
    &__title {
      font-size: 2.1rem;
      font-weight: 600;
      color: $color-primary;
    }
  }
}
</style>
