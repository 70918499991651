<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="footer__logo__wrapper col-md-3 col-12">
          <img
            class="footer__logo"
            src="@/assets/g-paper-clicks-logo.svg"
            alt=""
          />
        </div>
        <div class="footer__links__wrapper col-md-4 col-12">
          <div class="footer__links">
            <p class="footer__links__text">
              Company
            </p>
            <ul class="footer__links__list">
              <li>
                <router-link :to="{ name: 'About' }" class="primary-link "
                  >About Us</router-link
                >
              </li>
              <!-- <li>
                <router-link :to="{ name: 'Blog' }" class="primary-link "
                  >Blog</router-link
                >
              </li> -->
              <li>
                <router-link :to="{ name: 'Careers' }" class="primary-link "
                  >Careers</router-link
                >
              </li>
            </ul>
          </div>
          <div class="footer__links">
            <p class="footer__links__text">
              Products
            </p>
            <ul class="footer__links__list">
              <li>
                <a
                  href="https://theoptimizer.io/"
                  target="_blank"
                  class="primary-link "
                  >The Optimizer</a
                >
              </li>
              <li>
                <a
                  href="https://landerlab.io/"
                  target="_blank"
                  class="primary-link "
                  >Landerlab</a
                >
              </li>
              <li>
                <a
                  href="https://clickflare.io/"
                  target="_blank"
                  class="primary-link "
                  >Tracker</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__social-links__wrapper col-md-5 col-12">
          <div class="footer__social-links">
            <p class="footer__social-links__text">
              Follow Us
            </p>
            <div class="footer__social-links__icons">
              <a
                class="footer__social-links__icon-link"
                href="https://al.linkedin.com/company/paperclicks"
                target="_blank"
                ><img
                  src="@/assets/footer/linkedin-icon.svg"
                  alt="LinkedIn icon"
              /></a>
              <a
                class="footer__social-links__icon-link"
                href="https://www.instagram.com/paper.clicks/?hl=en "
                target="_blank"
                ><img
                  src="@/assets/footer/instagram-icon.svg"
                  alt="Instagram icon"
              /></a>
              <a
                class="footer__social-links__icon-link"
                href="https://www.facebook.com/paperclicks/"
                target="_blank"
                ><img
                  src="@/assets/footer/facebook-icon.svg"
                  alt="Facebook icon"
              /></a>
              <!-- <a class="footer__social-links__icon-link" href="#"
                ><img src="@/assets/footer/twitter-icon.svg" alt="Twitter icon"
              /></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copyright">
      <p class="footer__copyright__text">
        © {{currentYear}} PaperClicks. All rights reserved. 
          <span class="footer__copyright__adress">
            Rr. Tish Daija, Kompleksi Kika 2, Hyrja 3, Tirana, Albania 1001
          </span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 6rem 0 2rem 0;
  background-color: $color-light;
  @include media-breakpoint-down(sm) {
    padding: 6rem 2rem 2rem 2rem;
  }

  &__links {
    &__text {
      font-weight: 600;
      color: $color-primary-light;
      margin-bottom: 1.6rem;
    }
    &__list {
      list-style: none;
    }

    & .primary-link {
      margin-top: 0.5rem;

      @include media-breakpoint-down(sm) {
        margin-top: 1.4rem;
      }
    }
  }

  &__social-links {
    width: max-content;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }

    &__text {
      font-weight: 600;
      color: $color-primary-light;
    }

    &__icon-link {
      display: inline-block;
      padding: 2rem 2rem 0rem 0rem;
      outline: none;

      @include media-breakpoint-down(sm) {
        padding: 1rem 1.5rem 0rem 1.5rem;
      }

      img {
        height: 2.2rem;
        @include media-breakpoint-down(sm) {
          height: 3rem;
          margin-top: 1rem;
        }
      }
    }
  }

  &__logo {
    height: 2.6rem;

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      display: block;
      margin-bottom: 3rem;
      height: 3rem;
    }
  }
  &__copyright {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(68, 29, 130, 0.1);

    @include media-breakpoint-down(sm) {
      border: none;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    &__text {
      text-align: center;
      margin-top: 2rem;
      font-size: 1.4rem;
      color: $color-primary-light;
      opacity: 0.4;
    }

    &__adress{
      display: block;
      margin-top: 1rem;
    }
  }
}

.footer__links__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer__social-links__wrapper {
  justify-self: flex-end;

  @include media-breakpoint-down(sm) {
    border-top: 1px solid rgba(68, 29, 130, 0.1);
    padding-top: 3rem;
    margin-top: 3rem;
  }
}
</style>
