<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import "@/styles/main.scss";
import Header from "@/components/Layouts/Header";
import Footer from "./components/Layouts/Footer.vue";

export default {
  name: "App",
  components: { Header, Footer },
};
</script>

<style lang="scss"></style>
