export default {
  1: {
    id: 1,
    title: "Best Marketing Tools to use for your Start-Up",
    author: "Aldo Hysenaj",
    date: "Jun 19, 2021",
    category: "Design",
    image:
      "https://images.unsplash.com/photo-1555949963-ff9fe0c870eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    html: `
    <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Most people think about emotional intelligence as a skill, something you
        can build and train with practice.
    </p>
    <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        And while this is partly true, there’s a deeper truth about emotional
        intelligence that most of us miss:
    </p>
    <div style="margin-top: 4.8rem;">
        <h3 style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 4.8rem;">
        “Improving your emotional intelligence is often about what you do less
        of, not more of”
        </h3>

        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        As a psychologist, I work with many people who look like though they
        don’t have much emotional intelligence:
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They blame other people for their problems
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They trap themselves in cycles of stress and anxiety
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They self-sabotage as soon as they start to make progress
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        But it’s my experience that most people don’t actually lack the capacity
        for emotional intelligence. In fact, I think most people already have a
        high degree of emotional intelligence.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Unfortunately, many people are held back from using their innate
        emotional intelligence by a collection of bad habits that get in the
        way.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        If you’d like to improve your emotional intelligence, learn to identify
        these habits in your own life and work to eliminate them. I think you’ll
        find that your natural emotional intelligence is not far behind.
        </p>
        <img
        style="margin-top: 4.8rem; width: 100%;"
        src="https://images.unsplash.com/photo-1555949963-ff9fe0c870eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        alt=""
        />
    </div>
    <div style="margin-top: 4.8rem;">
        <p style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 2rem;">Criticizing Others</p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Criticizing others is often an unconscious defense mechanism aimed at
        alleviating our own insecurities.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        We’re all critical sometimes. And it’s not necessarily a bad thing — to
        think carefully and critically about the world around us is a vital
        skill. It helps us navigate the world and our relationships in an
        objective way. But too much criticism — especially the habit of being
        critical of others — can lead to the opposite of objectivity: it can
        make us narrow-minded and blind, especially to ourselves. One of the
        reasons it’s so easy to slip into habitually criticizing others is that
        it makes us feel good:
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        But too much criticism — especially the habit of being critical of
        others — can lead to the opposite of objectivity: it can make us
        narrow-minded and blind, especially to ourselves. One of the reasons
        it’s so easy to slip into habitually criticizing others is that it makes
        us feel good:
        </p>
    </div>
    <div style="margin-top: 4.8rem;">
        <p style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 2rem;">All You Need to Know</p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        If you want to increase your emotional intelligence, try approaching the
        problem backwards: Instead of trying to improve your emotional
        intelligence skills, strive to identify and eliminate the habits that
        are interfering with your natural emotional intelligence in the first
        place.
        </p>
    </div>
    `,
  },
  2: {
    id: 2,
    title: "Best Marketing Tools to use for your Start-Up",
    author: "Aldo Hysenaj",
    date: "Jun 19, 2021",
    category: "Design",
    image:
      "https://images.unsplash.com/photo-1497366811353-6870744d04b2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
    html: `
    <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Most people think about emotional intelligence as a skill, something you
        can build and train with practice.
    </p>
    <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        And while this is partly true, there’s a deeper truth about emotional
        intelligence that most of us miss:
    </p>
    <div style="margin-top: 4.8rem;">
        <h3 style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 4.8rem;">
        “Improving your emotional intelligence is often about what you do less
        of, not more of”
        </h3>

        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        As a psychologist, I work with many people who look like though they
        don’t have much emotional intelligence:
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They blame other people for their problems
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They trap themselves in cycles of stress and anxiety
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They self-sabotage as soon as they start to make progress
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        But it’s my experience that most people don’t actually lack the capacity
        for emotional intelligence. In fact, I think most people already have a
        high degree of emotional intelligence.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Unfortunately, many people are held back from using their innate
        emotional intelligence by a collection of bad habits that get in the
        way.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        If you’d like to improve your emotional intelligence, learn to identify
        these habits in your own life and work to eliminate them. I think you’ll
        find that your natural emotional intelligence is not far behind.
        </p>
        <img
        style="margin-top: 4.8rem; width: 100%;"
        src="https://images.unsplash.com/photo-1497366811353-6870744d04b2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
        alt=""
        />
    </div>
    <div style="margin-top: 4.8rem;">
        <p style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 2rem;">Criticizing Others</p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Criticizing others is often an unconscious defense mechanism aimed at
        alleviating our own insecurities.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        We’re all critical sometimes. And it’s not necessarily a bad thing — to
        think carefully and critically about the world around us is a vital
        skill. It helps us navigate the world and our relationships in an
        objective way. But too much criticism — especially the habit of being
        critical of others — can lead to the opposite of objectivity: it can
        make us narrow-minded and blind, especially to ourselves. One of the
        reasons it’s so easy to slip into habitually criticizing others is that
        it makes us feel good:
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        But too much criticism — especially the habit of being critical of
        others — can lead to the opposite of objectivity: it can make us
        narrow-minded and blind, especially to ourselves. One of the reasons
        it’s so easy to slip into habitually criticizing others is that it makes
        us feel good:
        </p>
    </div>
    <div style="margin-top: 4.8rem;">
        <p style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 2rem;">All You Need to Know</p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        If you want to increase your emotional intelligence, try approaching the
        problem backwards: Instead of trying to improve your emotional
        intelligence skills, strive to identify and eliminate the habits that
        are interfering with your natural emotional intelligence in the first
        place.
        </p>
    </div>
    `,
  },
  3: {
    id: 3,
    title: "Best Marketing Tools to use for your Start-Up",
    author: "Aldo Hysenaj",
    date: "Jun 19, 2021",
    category: "Design",
    image:
      "https://images.unsplash.com/photo-1511467687858-23d96c32e4ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    html: `
    <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Most people think about emotional intelligence as a skill, something you
        can build and train with practice.
    </p>
    <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        And while this is partly true, there’s a deeper truth about emotional
        intelligence that most of us miss:
    </p>
    <div style="margin-top: 4.8rem;">
        <h3 style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 4.8rem;">
        “Improving your emotional intelligence is often about what you do less
        of, not more of”
        </h3>

        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        As a psychologist, I work with many people who look like though they
        don’t have much emotional intelligence:
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They blame other people for their problems
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They trap themselves in cycles of stress and anxiety
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        They self-sabotage as soon as they start to make progress
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        But it’s my experience that most people don’t actually lack the capacity
        for emotional intelligence. In fact, I think most people already have a
        high degree of emotional intelligence.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Unfortunately, many people are held back from using their innate
        emotional intelligence by a collection of bad habits that get in the
        way.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        If you’d like to improve your emotional intelligence, learn to identify
        these habits in your own life and work to eliminate them. I think you’ll
        find that your natural emotional intelligence is not far behind.
        </p>
        <img
        style="margin-top: 4.8rem; width: 100%;"
        src="https://images.unsplash.com/photo-1511467687858-23d96c32e4ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        alt=""
        />
    </div>
    <div style="margin-top: 4.8rem;">
        <p style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 2rem;">Criticizing Others</p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        Criticizing others is often an unconscious defense mechanism aimed at
        alleviating our own insecurities.
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        We’re all critical sometimes. And it’s not necessarily a bad thing — to
        think carefully and critically about the world around us is a vital
        skill. It helps us navigate the world and our relationships in an
        objective way. But too much criticism — especially the habit of being
        critical of others — can lead to the opposite of objectivity: it can
        make us narrow-minded and blind, especially to ourselves. One of the
        reasons it’s so easy to slip into habitually criticizing others is that
        it makes us feel good:
        </p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        But too much criticism — especially the habit of being critical of
        others — can lead to the opposite of objectivity: it can make us
        narrow-minded and blind, especially to ourselves. One of the reasons
        it’s so easy to slip into habitually criticizing others is that it makes
        us feel good:
        </p>
    </div>
    <div style="margin-top: 4.8rem;">
        <p style="color: #2a1748; font-size: 2.3rem; font-weight: 800; margin-bottom: 2rem;">All You Need to Know</p>
        <p style="font-size: 1.6rem; font-weight: 300; color:#606468; line-height: 2.6rem;">
        If you want to increase your emotional intelligence, try approaching the
        problem backwards: Instead of trying to improve your emotional
        intelligence skills, strive to identify and eliminate the habits that
        are interfering with your natural emotional intelligence in the first
        place.
        </p>
    </div>
    `,
  },
};
