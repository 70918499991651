<template>
  <header class="header fixed-top">
    <b-navbar
      toggleable="lg"
      :class="[
        fixedNav ? 'navbar-top-nav' : '',
        'navbar navbar-expand-lg',
        shown ? 'expanded-navbar' : '',
      ]"
    >
      <b-container>
        <div class="navbar-wrapper">
          <div class="navbar-wrapper__header">
            <router-link
              :to="{ name: 'Home' }"
              class="navbar-brand navbar-header__brand"
            >
              <img v-if="!shown" src="@/assets/g-paper-clicks-logo.svg" />
              <img v-else src="@/assets/g-paper-clicks-logo-white.svg" />
            </router-link>

            <b-navbar-toggle
              target="navbar-collapse"
              @click="checkNavbarCollapsed()"
            >
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </b-navbar-toggle>
          </div>
          <b-collapse id="navbar-collapse" is-nav>
            <div class="navbar-nav">
              <ul class="navbar-nav__nav-items">
                <b-nav-item
                  @click="navBackgorundReset()"
                  :to="{ name: 'Home' }"
                >
                  Home
                </b-nav-item>
                <b-nav-item
                  @click="navBackgorundReset()"
                  :to="{ name: 'About' }"
                >
                  About Us
                </b-nav-item>
                <b-nav-item
                  @click="navBackgorundReset()"
                  class="nav-item--button"
                  :to="'/about#job-positions'"
                >
                  Join Our Team
                </b-nav-item>
                <!-- Social icon Links -- only visible on mobile -->
                <div v-if="shown" class="navbar__social-links">
                  <p class="navbar__social-links__text">Follow Us</p>
                  <div class="navbar__social-links__icons">
                    <a
                      class="navbar__social-links__icon"
                      href="https://al.linkedin.com/company/paperclicks"
                      target="_blank"
                      ><img
                        src="@/assets/header/linkedin-icon-white.svg"
                        alt="LinkedIn icon"
                    /></a>
                    <a
                      class="navbar__social-links__icon"
                      href="https://www.instagram.com/paper.clicks/?hl=en "
                      target="_blank"
                      ><img
                        src="@/assets/header/instagram-icon-white.svg"
                        alt="Instagram icon"
                    /></a>
                    <a
                      class="navbar__social-links__icon"
                      href="https://www.facebook.com/paperclicks/"
                      target="_blank"
                      ><img
                        src="@/assets/header/facebook-icon-white.svg"
                        alt="Facebook icon"
                    /></a>
                    <a class="navbar__social-links__icon" href="#"
                      ><img
                        src="@/assets/header/twitter-icon-white.svg"
                        alt="Twitter icon"
                    /></a>
                  </div>
                </div>
              </ul>
            </div>
          </b-collapse>
        </div>
      </b-container>
    </b-navbar>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      fixedNav: false,
      shown: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.myEventHandler);
  },

  watch: {
    watchWindowWidth() {
      const windowWidth = window.innerWidth;
    },
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 50 && !this.fixedNav) {
        this.fixedNav = true;
      } else if (window.scrollY <= 50 && this.fixedNav) {
        this.fixedNav = false;
      }
    },
    checkNavbarCollapsed() {
      const classes = document.querySelector("#navbar-collapse").classList;
      this.shown = ![...classes].includes("show");
    },
    navBackgorundReset() {
      this.shown = false;
    },
    myEventHandler(e) {
      if (e.target.innerWidth > 990) {
        this.shown = false;
      }
      return;
    },
  },
};
</script>

<style lang="scss">
.header {
  .navbar {
    .navbar-collapse {
      .navbar-nav {
        &__actions {
          a {
            &:first-of-type {
              color: #e9eaec;
            }
          }
        }
      }
    }
  }

  .navbar {
    padding: 1rem 0;
    box-shadow: none;
    transition: all 0.25s ease-in-out;
    background: $color-white;

    @include media-breakpoint-down(md) {
      box-shadow: $box-shadow-navigation;
      padding: 1rem 2rem;
    }

    &.navbar-top-nav {
      box-shadow: $box-shadow-navigation;
      padding: 1rem 0;

      @include media-breakpoint-down(md) {
        padding: 1rem 2rem;
      }

      .navbar-nav__nav-items {
        li {
          a {
            color: $color-primary !important;

            &:hover {
              color: $color-primary !important;
            }

            &::after {
              background: $color-primary !important;
            }

            @include media-breakpoint-down(md) {
              color: $color-white !important;

              &:hover {
                color: $color-white !important;
              }

              &::after {
                background: $color-white !important;
              }
            }
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include media-breakpoint-down(md) {
        display: block;
      }

      &__header {
        @include media-breakpoint-down(md) {
          display: flex;
        }
      }
    }

    &-brand {
      img {
        height: 3.2rem;
      }
    }

    &-toggler {
      outline: none;
      border: none;

      .bar1,
      .bar2,
      .bar3 {
        width: 2.8rem;
        height: 0.2rem;
        background: $color-primary;
        margin: 0.5rem 0;
        transition: 0.4s;
        color: $color-primary;
      }

      @include media-breakpoint-down(md) {
        display: inline-block;
        margin-left: auto;
      }

      &[aria-expanded="true"] {
        .bar1 {
          transform: rotate(45deg);
          margin-bottom: -1.5rem;
        }

        .bar2 {
          display: none;
        }

        .bar3 {
          transform: rotate(-45deg);
          margin-top: 1.3rem;
        }
      }
    }

    &-collapse {
      text-align: right;

      @include media-breakpoint-down(md) {
        border-top: 0.1rem solid rgba($color-primary, 0.1);
        margin-top: 1.5rem;
        height: calc(100vh - 6.8rem);
        text-align: left;
      }
      // primary button styling
      .primary-button {
        color: $color-white !important;
        padding: 1.5rem 3rem !important;
        margin-left: 3rem;
        line-height: 1.5rem;

        @include media-breakpoint-down(md) {
          width: 100%;
          padding: 2rem 3rem !important;
          margin: 5rem 0rem 0 0;
        }
      }

      .navbar-nav {
        display: block;
        width: 100%;
        // nav items styling collapsed
        &__nav-items {
          list-style: none;
          display: inline-block;
          margin-bottom: 0;

          @include media-breakpoint-down(md) {
            margin-right: 0;
            display: block;
            height: calc(100vh - 10rem);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          li {
            display: inline;
            margin-right: 3rem;

            &:last-of-type {
              margin-right: 0;
            }

            @include media-breakpoint-down(md) {
              display: block;
              width: 100%;
              padding: 1.5rem 0;
              margin: 0;
            }

            a {
              display: inline-flex;
              flex-direction: column;
              color: $color-primary;
              font-weight: 500;
              font-size: 1.6rem;
              transition: color 0.25s ease-in-out;

              &:hover {
                color: $color-primary;

                &::after {
                  transform: scaleX(1);
                }
              }

              &::after {
                display: block;
                content: "";
                height: 0.1rem;
                width: 100%;
                transform: scaleX(0);
                background: $color-primary;
                transition: transform 0.2s ease-in-out;
              }

              @include media-breakpoint-down(md) {
                color: $color-white;
                font-size: 2rem;

                &:hover {
                  color: $color-white;
                }
              }
            }
          }
        }

        &__actions {
          display: inline-block;
          margin-left: 4rem;

          @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 3rem;
          }

          a {
            &:first-of-type {
              margin-right: 2rem;
              font-size: 1.7rem;
              color: #e9eaec;
              white-space: nowrap;

              @include media-breakpoint-down(md) {
                font-size: 1.9rem;
                color: $color-primary !important;
              }
            }
          }

          .primary-button {
            padding: 1rem 1rem;
            font-weight: 700;
            font-size: 1.5rem;
            border-radius: 0.5rem;
            line-height: 1.3;

            @include media-breakpoint-down(md) {
              font-size: 1.9rem;
            }
          }
        }
      }
    }
  }

  .expanded-navbar {
    background: #441d82;
  }

  .navbar-toggler.not-collapsed {
    .bar1,
    .bar2,
    .bar3 {
      color: $color-white;
      background: $color-white;
    }
  }

  .navbar__social-links {
    position: absolute;
    bottom: 12rem;
    left: 50%;
    transform: translate(-50%);
    text-align: center;

    @media screen and (max-height: 620px) {
      display: none;
    }
    &__text {
      color: $color-white;
      font-weight: 500;
      font-size: 1.8rem;
      margin-top: 0;
    }
    &__icons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &__icon {
      margin: 2rem 1.2rem 0 1.2rem;
      height: 2.3rem;
    }
  }
}

// Hide border animation on mobile (navbar items)
.header .navbar-collapse .navbar-nav__nav-items li a::after {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

// Primary button styling (navbar)
.header .navbar .navbar-nav__nav-items .nav-item--button a {
  display: inline-block;
  padding: 1.2rem 3rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: $color-white !important;

  background-color: $color-primary-light;
  border-radius: $border-radius-sm;

  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  transition: background-color 0.25s ease-in-out;

  background-color: $color-pink;

  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-pink, 10%) !important;
  }

  &::before,
  &::after {
    display: none;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 1.5rem 4rem;
    margin-top: 4rem;
  }
}

.header .navbar.navbar-top-nav .navbar-nav__nav-items .nav-item--button a {
  color: $color-white !important;
  &:hover,
  &:focus,
  &:active {
    color: $color-white !important;
  }
}
</style>
