<template>
  <section class="video-section">
    <div class="container">
      <div class="row video-section__row">
        <div class="col-lg-6 col-12 video-section__image" @click="showModal">
          <img src="@/assets/home/video-image.jpg" alt="" />
          <svg
            width="76"
            height="76"
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.5 38.0217C0.5 17.3234 17.3316 0.5 38 0.5C58.6684 0.5 75.5 17.3234 75.5 38.0217C75.5 58.6766 58.6684 75.5 38 75.5C17.3316 75.5 0.5 58.6766 0.5 38.0217ZM51.7548 41.8169C52.153 41.419 52.6597 40.8039 52.7683 40.6592C53.3475 39.8994 53.6371 38.9588 53.6371 38.0217C53.6371 36.9689 53.3113 35.992 52.6959 35.1961C52.6455 35.1457 52.5468 35.0382 52.4181 34.8981C52.1773 34.6361 51.8317 34.2599 51.5014 33.9298C48.5333 30.746 40.7872 25.5362 36.7331 23.9443C36.1178 23.6946 34.5613 23.1483 33.7288 23.1122C32.9324 23.1122 32.1723 23.2931 31.4484 23.6548C30.5434 24.1614 29.8195 24.9573 29.4213 25.898C29.168 26.5492 28.7698 28.5029 28.7698 28.5391C28.3716 30.6737 28.1544 34.1469 28.1544 37.9819C28.1544 41.6397 28.3716 44.9645 28.6974 47.1353C28.7064 47.1443 28.7375 47.2995 28.7859 47.5407C28.9329 48.274 29.2393 49.8015 29.5661 50.4276C30.3624 51.9472 31.9189 52.8878 33.584 52.8878H33.7288C34.8147 52.8517 37.0951 51.911 37.0951 51.8748C40.9319 50.2829 48.4971 45.3263 51.5376 42.034L51.7548 41.8169Z"
              fill="white"
              fill-opacity="0.9"
            />
          </svg>
        </div>
        <div class="col-lg-6 col-12">
          <div class="video-section__content">
            <h3 class="section-title">
              A Sneak Peek of our Offices
            </h3>
            <p class="body-text">
              Your future desk, the kitchen, the wireframes, the veranda, the
              colleague you’ll be working with and cracking a joke, this is but
              a glimpse into everyday life at PaperClicks. The next chapter of
              our story is being written, and you are welcomed to leave your
              mark too.
            </p>
          </div>
        </div>
      </div>
    </div>
    <video-modal ref="modal"></video-modal>
  </section>
</template>

<script>
import VideoModal from "./VideoModal.vue";

export default {
  name: "VideoSection",
  components: { VideoModal },
  methods: {
    showModal() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style lang="scss">
.video-section {
  margin-top: 20rem;

  &__row {
    // @include media-breakpoint-down(md) {
    // }
  }
  &__image {
    position: relative;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      order: 2 !important;
      margin-top: 5rem;
    }

    img {
      width: 90%;
      cursor: pointer !important;
      border-radius: $border-radius-md;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 5rem;
      transform: translate(-85%, -50%);
    }
  }

  &__content {
    margin-right: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .body-text {
      margin-top: 3rem;
    }
  }
}
</style>
