<template>
  <div class="feature-item">
    <img :src="iconImage" alt="" class="feature-item__img" />
    <h5 class="feature-item__title">{{ title }}</h5>
    <p class="feature-item__text">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "FeatureCard",
  props: {
    title: { type: String },
    description: { type: String },
    image: { type: String },
  },
  computed: {
    iconImage() {
      return require(`../../assets/home/${this.image}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-item {
  margin-top: 7rem;
  padding-right: 0;

  @include media-breakpoint-up(xs) {
    margin-top: 10rem;
    padding-right: 15rem;
  }

  @include media-breakpoint-up(md) {
    margin-top: 10rem;
    padding-right: 7rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 13rem;
    padding-right: 7rem;
  }

  &__img {
    height: 6.5rem !important;

    @include media-breakpoint-up(md) {
      height: 8rem !important;
    }
    @include media-breakpoint-up(xl) {
      height: 9rem !important;
    }
  }
  &__title {
    margin-top: 2rem;
    color: $color-black;
    font-size: 1.9rem;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      margin-top: 2.3rem;
      font-size: 2rem;
    }
  }
  &__text {
    margin-top: 2rem;
    color: $color-primary-80;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }
}
</style>
