<template>
  <section class="feature-section">
    <div class="container feature-section__container">
      <h3 class="section-title">What Makes & Differentiates Us</h3>
      <div class="row">
        <div class="col-sm-12 col-lg-9">
          <b-row class="feature-section__row">
            <div
              class="col-sm-12 col-md-6 col-lg-6 feature-section__item"
              v-for="({ image, description, title }, index) in feature"
              :key="index"
            >
              <FeatureItem
                :description="description"
                :title="title"
                :image="image"
              />
            </div>
          </b-row>
        </div>
      </div>
    </div>
    <img
      class="feature-section__bg-img"
      src="@/assets/home/features-bg.svg"
      alt=""
    />
  </section>
</template>

<script>
import FeatureItem from "./FeatureItem.vue";
export default {
  name: "FeatureSection",
  data() {
    return {
      feature: [
        {
          image: "feature-icon-1",
          title: "The Products",
          description:
            "Proud creators of digital marketing’s finest tools; used daily by thousands of customers all around the world to manage and optimize their campaigns. ",
        },
        {
          image: "feature-icon-2",
          title: "The Origins ",
          description:
            "Founded by industry leaders with more than a decade of experience, a knack for the next innovations to come and a commitment to help digital marketers. ",
        },
        {
          image: "feature-icon-3",
          title: "The Journey",
          description:
            "From an idea to a finished product, all is done in-house by our teams of talented engineers, programmers, digital marketers, designers and artists.",
        },
        {
          image: "feature-icon-4",
          title: "The Vibe",
          description:
            "We work to create and enable a space were creativity, openness and inclusiveness are the norm; where each team member can be the best they can be. ",
        },
        {
          image: "feature-icon-5",
          title: "The Tech",
          description:
            "We love to experiment and always be on top of the latest technologies and methods being used, to elevate our work and deliver constantly finer products. ",
        },
        {
          image: "feature-icon-6",
          title: "The Next",
          description:
            "One of Albania’s fastest growing tech companies. We’re constantly looking to recruit talented individuals and bring products & features that matter to the market. ",
        },
      ],
    };
  },
  components: {
    FeatureItem,
  },
};
</script>

<style lang="scss" scoped>
.feature-section {
  position: relative;
  margin-top: 11rem;

  @include media-breakpoint-up(md) {
    margin-top: 17rem;
  }

  &__item {
    margin-top: 0;
  }

  &__bg-img {
    position: absolute;
    top: 5%;
    right: 0;
    height: 33rem;
    z-index: -10;

    @include media-breakpoint-up(sm) {
      top: 5%;
      height: 45rem;
    }

    @include media-breakpoint-up(md) {
      top: 50%;
      transform: translateY(-50%);
      height: 65%;
    }

    @include media-breakpoint-up(xl) {
      top: 50%;
      transform: translateY(-50%);
      height: 70%;
    }
  }
}
</style>
