<template>
  <a :href="link" target="_blank" class="product-link">
    <div class="product">
      <img :src="getProductImages" class="product__img" />

      <div class="product__info" :class="`product__info--${category}`">
        <h3 class="product__info__title">
          {{ title }}
        </h3>
        <p class="product__info__text body-text body-text--purple">
          {{ text }}
        </p>
        <h3 class="product__info__title">Technologies used:</h3>
        <p
          class="product__info__text body-text body-text--bold  body-text--purple"
        >
          {{ technologies }}
        </p>
        <p class="product__info__read-more">
          Read more
          <svg
            width="37"
            height="24"
            viewBox="0 0 37 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.0607 13.0607C36.6464 12.4749 36.6464 11.5251 36.0607 10.9393L26.5147 1.3934C25.9289 0.80761 24.9792 0.80761 24.3934 1.3934C23.8076 1.97918 23.8076 2.92893 24.3934 3.51472L32.8787 12L24.3934 20.4853C23.8076 21.0711 23.8076 22.0208 24.3934 22.6066C24.9792 23.1924 25.9289 23.1924 26.5147 22.6066L36.0607 13.0607ZM1.31134e-07 13.5L35 13.5L35 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z"
              fill="white"
            />
          </svg>
        </p>
      </div>
    </div>
    <!-- <div class="product-card">
      <img :src="getProductImages" class="product-card__image" />
      <div class="product-card__bottom" :class="title">
        <h3 class="product-card__bottom__title">{{ title }}</h3>
        <p class="product-card__bottom__text">
          {{ text }}
        </p>
        <h3 class="product-card__bottom__title product-card__bottom__title--sm">
          Technologies used:
        </h3>
        <p class="product-card__bottom__text product-card__bottom__text--bold">
          {{ technologies }}
        </p>
        <p class="product-card__bottom__read-more">
          Read more
          <svg
            width="37"
            height="24"
            viewBox="0 0 37 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.0607 13.0607C36.6464 12.4749 36.6464 11.5251 36.0607 10.9393L26.5147 1.3934C25.9289 0.80761 24.9792 0.80761 24.3934 1.3934C23.8076 1.97918 23.8076 2.92893 24.3934 3.51472L32.8787 12L24.3934 20.4853C23.8076 21.0711 23.8076 22.0208 24.3934 22.6066C24.9792 23.1924 25.9289 23.1924 26.5147 22.6066L36.0607 13.0607ZM1.31134e-07 13.5L35 13.5L35 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z"
              fill="white"
            />
          </svg>
        </p>
      </div>
    </div> -->
  </a>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    category: { type: String },
    title: { type: String },
    text: { type: String },
    link: { type: String },
    technologies: { type: String },
  },
  computed: {
    getProductImages() {
      return require(`@/assets/home/product-${this.category}-img.jpg`);
    },
  },
};
</script>

<style lang="scss" scoped>
// Product Card Styling
.product-link {
  display: block;
  max-width: min-content;
}

.product {
  height: 41rem;
  overflow: hidden;
  box-shadow: $box-shadow-lg;
  border-radius: $border-radius-lg;

  @include media-breakpoint-down(xxl) {
    height: 38rem;
  }
  @include media-breakpoint-down(xl) {
    overflow: visible;
    height: 100%;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &__img {
    height: 41rem;
    border-radius: $border-radius-lg;

    @include media-breakpoint-down(xxl) {
      height: 38rem;
    }
    @include media-breakpoint-down(xl) {
      height: 32rem;
    }
    @include media-breakpoint-down(lg) {
      height: auto;
      width: 60vw;
    }
    @include media-breakpoint-down(xs) {
      height: auto;
      width: 90vw;
    }
  }

  &__info {
    position: relative;
    height: 41rem;
    padding: 4rem;
    background: $color-pink;
    transform: translateY(0);
    transition: all 0.25s ease-in-out;
    border-radius: $border-radius-lg;
    @include media-breakpoint-down(xxl) {
      height: 38rem;
    }
    @include media-breakpoint-down(xl) {
      height: 40rem;
      padding: 3rem;
      margin-top: -13rem;
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
    }

    &--optimizer {
      background: #58c3e6;
    }
    &--landerlab {
      background: #ed7796;
    }
    &--clickflare {
      background: #e8b879;
    }

    &--optimizer,
    &--landerlab,
    &--clickflare {
      @include media-breakpoint-down(xl) {
        background: $color-white;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 1.9rem;
      margin-bottom: 1rem;
      color: $color-white;
      @include media-breakpoint-down(xl) {
        color: $color-purple;
      }
    }

    &__text {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }

    &__read-more {
      position: absolute;
      bottom: 2rem;
      right: 3rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: $color-white;
      @include media-breakpoint-down(xl) {
        color: $color-purple;
      }

      svg {
        height: 1.7rem;
        @include media-breakpoint-down(xl) {
          path {
            fill: $color-purple;
          }
        }
      }
    }
  }
}

// Move product information up
.product:hover {
  .product__info {
    transform: translateY(-100%);
    @include media-breakpoint-down(xl) {
      transform: translateY(0);
    }
  }
}
</style>
