<template>
  <div class="home">
    <IntroSection />

    <VideoSection />

    <FeaturesSection />

    <ProductsSection />
    <!-- <BlogSection /> -->
    <JoinSection />
    <div class="container button--container sticky-button">
      <b-btn
        :to="'/about#job-positions'"
        class="primary-button primary-button--pink primary-button--sticky "
        >We are Hiring!</b-btn
      >
    </div>
  </div>
</template>

<script>
import IntroSection from "@/components/home/IntroSection.vue";
import VideoSection from "@/components/home/VideoSection.vue";
import FeaturesSection from "@/components/home/FeatureSection.vue";
import ProductsSection from "@/components/home/ProductsSection.vue";
import BlogSection from "@/components/home/BlogSection.vue";
import JoinSection from "@/components/home/JoinSection.vue";

export default {
  name: "Home",
  components: {
    IntroSection,
    VideoSection,
    FeaturesSection,
    ProductsSection,
    BlogSection,
    JoinSection,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const buttonContainer = document.querySelector(".button--container");
      const secondSectionCoords = document
        .querySelector(".feature-section__container")
        .getBoundingClientRect().top;

      const lastSectionCoords = document
        .querySelector(".join-section")
        .getBoundingClientRect().top;

      if (
        window.scrollY > secondSectionCoords + 600 &&
        lastSectionCoords - window.innerHeight > 0
      ) {
        buttonContainer.classList.remove("sticky-button");
      } else {
        buttonContainer.classList.add("sticky-button");
      }
    },
  },
};
</script>

<style lang="scss">
.home {
  margin-top: 14rem;
}

// delete this when blog section is shown
.join-section {
  margin-top: 17rem;
  @include media-breakpoint-down(md) {
    margin-top: 12rem;
  }
}

.button--container {
  position: sticky;
  bottom: 2rem;
  z-index: 1000;
}

.sticky-button {
  display: none !important;
  opacity: 0;
}

.primary-button--sticky {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(68, 29, 130, 0.2) !important;
  animation: showButton 0.5s ease-in-out;
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

@keyframes showButton {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
