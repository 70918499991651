<template>
  <section class="intro-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-5">
          <div class="intro-section__content text-center text-lg-left">
            <h1 class="primary-heading">
              One of Albania’s Fastest Growing Tech Companies
            </h1>
            <!-- Backgorund image Mobile -->
            <img
              class="intro-section__image-mobile"
              src="@/assets/home/intro-bg.svg"
              alt="Illustration"
            />
            <b-btn
              :to="'/about#job-positions'"
              class="primary-button primary-button--pink"
              >We are Hiring!</b-btn
            >
          </div>
        </div>
        <div class="col-sm-12 col-lg-7">
          <!-- Backgorund image Desktop -->
          <div class="intro-section__image">
            <img
              class="intro-section__image"
              src="@/assets/home/intro-bg.svg"
              alt="Illustration"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "IntroSection",
};
</script>

<style lang="scss">
.intro-section {
  margin-top: 17rem;

  @include media-breakpoint-down(md) {
    margin-top: -1rem !important;
  }

  .row {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      justify-content: center;
      align-items: center;
    }
  }

  // Backgorund image Desktop
  &__image {
    width: 107%;
    margin-left: -3rem;

    @include media-breakpoint-up(xl) {
      width: 110%;
      margin-left: -3rem;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-left: 0rem;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  // Backgorund image Mobile
  &__image-mobile {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      margin: 7rem auto 4rem auto;
    }
  }

  .primary-button {
    width: max-content;
    margin-top: 4rem;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-left: 1px;
    }
  }

  .primary-heading {
    width: 100%;
    color: $color-primary;
    font-size: 4.1rem;
    font-weight: 800 !important;
    line-height: 6rem;

    @include media-breakpoint-down(lg) {
      width: 100%;
      font-size: 3.8rem;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin: 0 auto;
      font-size: 3.6rem;
      line-height: 5.5rem;
      text-align: center;
    }

    @include media-breakpoint-down(xs) {
      font-size: 3.5rem;
      width: 90%;
    }
  }
}
</style>
