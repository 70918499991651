<template>
  <section class="join-section">
    <div class="container join-section__container">
      <div class="join-section__content">
        <p class="join-section__content__title">
          Want to join <span>PaperClicks’ team?</span>
        </p>
        <p class="body-text--white">
          One of Albania’s fastest growing tech companies. We’re constantly
          looking to recruit talented individuals and deliver products.
        </p>
        <div class="join-section__benefits">
          <ul class="join-section__benefits__list">
            <li class="join-section__benefits__list__items">
              <img src="@/assets/careers/check-icon.svg" alt="" />
              <p>A competitive salary</p>
            </li>
            <li class="join-section__benefits__list__items">
              <img src="@/assets/careers/check-icon.svg" alt="" />
              <p>Goal-oriented working mentality</p>
            </li>
            <li class="join-section__benefits__list__items">
              <img src="@/assets/careers/check-icon.svg" alt="" />
              <p>Flexible office hours</p>
            </li>
            <li class="join-section__benefits__list__items">
              <img src="@/assets/careers/check-icon.svg" alt="" />
              <p>State of the art workspace</p>
            </li>
            <li class="join-section__benefits__list__items">
              <img src="@/assets/careers/check-icon.svg" alt="" />
              <p>Perfect environment to hone your skills</p>
            </li>
            <li class="join-section__benefits__list__items">
              <img src="@/assets/careers/check-icon.svg" alt="" />
              <p>Various team building activities</p>
            </li>
          </ul>
        </div>
        <b-btn
          :to="'/about#job-positions'"
          class="primary-button primary-button--white"
          >Check Positions</b-btn
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "JoinSection",
};
</script>

<style lang="scss">
.join-section {
  margin-top: 10rem;
  margin-bottom: 13rem;
  background: none;

  @include media-breakpoint-down(md) {
    background: #441d82;
    width: 100%;
  }

  &__container {
    width: 100%;
    background: #441d82;
    border-radius: $border-radius-lg;
    height: max-content;
    overflow: hidden;
    padding: 0 !important;
    @include media-breakpoint-down(md) {
      background: none;
    }
  }

  &__content {
    background: url("../../assets/home/join-bg-desktop.svg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 70%;
    padding: 4rem 5rem;
    border-radius: $border-radius-lg;
    @include media-breakpoint-down(md) {
      padding: 4rem;
    }
    @include media-breakpoint-down(md) {
      background: url("../../assets/home/join-bg-mobile.svg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100%;
      padding-top: 11rem;
      padding-bottom: 11rem;
    }

    &__title {
      font-size: 1.9rem;
      font-weight: 500;
      text-align: start;
      color: $color-white;
      @include media-breakpoint-down(md) {
        font-size: 2.5rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        span {
          display: block;
        }
      }
      @include media-breakpoint-down(xs) {
        font-size: 2.7rem;
      }
    }

    .body-text--white {
      font-weight: 300;
      font-size: 1.6rem;
      width: 60%;
      margin-top: 2rem;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    & .primary-button {
      display: block;
      width: max-content;
      // margin-left: auto;
      // margin-right: auto;
      margin-top: 3rem;

      @include media-breakpoint-down(md) {
        margin-top: 5rem;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 5rem;
      }
    }
  }

  &__benefits {
    width: 75%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    &__list {
      list-style: none;
      display: grid;
      grid-template-columns: 30% 35% 35%;
      gap: 1rem;
      row-gap: 2rem;
      margin-top: 3rem;
      @include media-breakpoint-down(md) {
        grid-template-columns: 100%;
        gap: 3rem;
      }
      &__items {
        display: flex;
        align-items: flex-start;
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.8);
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
        img {
          margin-right: 1rem;
          margin-top: 0.2rem;
          height: 2rem;
          padding-top: 0.1rem;
        }
      }
    }
  }
}
</style>
