<template>
  <section class="blog-section">
    <div class="container">
      <h3 class="section-title">The Latest from our Blog</h3>
      <b-row align-h="between">
        <div
          class="col-md-4"
          v-for="{ image, title, date, id } in articles"
          :key="id"
        >
          <BlogCard :image="image" :title="title" :date="date" :id="id" />
        </div>
      </b-row>
    </div>
  </section>
</template>

<script>
import BlogCard from "../BlogCard.vue";

import articles from "@/content/articles.js";

export default {
  name: "BlogSection",
  data() {
    return {
      articles,
    };
  },
  components: {
    BlogCard,
  },
};
</script>

<style lang="scss">
.blog-section {
  margin-top: 15rem;

  & blog-card {
    outline: none !important;
  }

  & .section-title {
    margin-bottom: 3rem;
    text-align: center;
  }

  & .primary-link {
    display: block;
    margin-top: 8rem;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
    }
  }
}
</style>
