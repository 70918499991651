<template>
  <b-modal
    ref="modal"
    hide-footer
    hide-header
    centered
    size="xl"
    modal-class="video-modal"
  >
    <video
      src="@/assets/home/video-paperclicks-home.mp4"
      autoplay
      controls
      controlsList="nodownload"
      preload="none"
      width="100%"
    ></video>
    <a @click="hide" class="video-modal__close-icon">&#10005;</a>
  </b-modal>
</template>

<script>
export default {
  name: "VideoModal",
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss">
.video-modal {
  video {
    pointer-events: all !important;
  }

  &__close-icon {
    position: absolute;
    top: -1.5rem;
    right: -4rem;
    font-size: 2.5rem;
    font-weight: bold;
    color: $color-white;
    font-weight: 600;
    cursor: pointer;
    padding: 1rem;
    &:hover {
      color: rgb(231, 15, 15);
    }

    @include media-breakpoint-down(xs) {
      right: 0rem;
      top: -5rem;
    }
  }

  .modal-content {
    border: none;
    .modal-body {
      padding: 0;
      margin-bottom: -1rem;
    }
  }
}
</style>
